// .multiselect--active {
//     z-index: 9999999999;
//   }
//   .multiselect__content-wrapper {
//       z-index: 9999999999 !important;
//       overflow: visible !important;
//     //   display: block !important;
//     overflow: visible;
//   }
//   .multiselect__content {
//       z-index: 9999999999 !important;
//       overflow: visible;
//   }
//   .vs-table--content {
//       // z-index: 20 !important;
//       // overflow: visible;
//   }

.expand-link {
  display: flex;
  width: 100%;
  height: 47px;
  background-color: rgba(249, 249, 249, 1);
  color: rgba(53, 53, 53, 1);
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center;
  line-height: 47px;
  border: 0;
  text-decoration: none; /* 텍스트 밑줄 제거 */
  transition:
    background-color 0.3s ease,
    color 0.3s ease; /* 부드러운 효과 */
}

/* 마우스 오버 효과 */
.expand-link:hover {
  background-color: rgba(220, 220, 220, 1); /* 배경색 변경 */
  color: rgba(0, 0, 0, 1); /* 글자색 변경 */
}

/* 클릭했을 때 효과 */
.expand-link:active {
  user-select: none !important;
  background-color: rgba(200, 200, 200, 1); /* 배경색 변경 */
}

/* 포커스했을 때 효과 */
.expand-link:focus {
  user-select: none !important;
  cursor: pointer; /* 마우스 커서를 포인터로 설정 */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 128, 255, 0.5); /* 테두리에 그림자 추가 */
}

.arrow-container {
  margin-left: 10px; /* 텍스트와 화살표 사이의 간격을 조절 */
}

$main-font-color: #353535;
$strong-font-color: #3373ba;
$p-font-size: 16px !default;

.fr-box.fr-basic .fr-element.fr-view {
  color: $main-font-color;
  font-size: $p-font-size;
}

.fr-element.fr-view hr {
  border-top: 1px solid #f0f0f0;
  margin-top: 40px;
  margin-bottom: 40px;
}
.fr-popup.fr-desktop.fr-ltr {
  z-index: 99999999 !important;
}

.mobile-button {
  width: 25px;
  padding: 5px;
  background-color: #007bff;
  color: #fff !important;
  border-radius: 10px;
  margin-right: 10px;
}
.show_hidden {
  display: none;
}
.fr-element.fr-view p,
.fr-element.fr-view blockquote {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 12px;
}
.fr-view blockquote,
.fr-element.fr-view blockquote {
  // border-left: 0;
  border-left: solid 4px #353535;
  margin-left: 0;
  padding-left: 30px;
  color: $main-font-color;
}

p.vb_h2,
.vb_h2,
.fr-element.fr-view p.vb_h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.4;
  color: #3373ba;
}
p.vb_h3,
.vb_h3,
.fr-element.fr-view p.vb_h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  margin: 12px 0;
}
p.vb_image_description,
.vb_image_description {
  font-size: 12px;
  color: #898989;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 12px;
}
p.vb_add_description,
.vb_add_description,
.fr-element.fr-view .vb_add_description {
  font-size: 12px;
  color: #898989;
  line-height: 1.4;
  margin-bottom: 12px;
}
p.vb_table_text,
.vb_table_text,
.fr-element.fr-view .vb_table_text {
  font-size: 12px;
  color: #353535;
  line-height: 1.4;
  margin-bottom: 6px;
}
// 노 마진 단락
p.vb_no_margin,
.vb_no_margin,
.fr-element.fr-view .vb_no_margin {
  margin: 0 !important;
  .fr-view img.fr-dib {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  img {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.fr-element.fr-view .fr-img-caption .fr-img-wrap > span {
  font-size: 12px;
  color: #898989;
  line-height: 1.4;
  text-align: center;
  margin: 0;
  padding-bottom: 7px;
}
.fr-element.fr-view table p {
  font-size: 12px;
  line-height: 1.4;
  color: #353535;
  margin-bottom: 6px;
}
.fr-element.fr-view table br {
  display: block; /* makes it have a width */
  content: ''; /* clears default height */
  margin-bottom: 6px; /* change this to whatever height you want it */
  line-height: 1.4;
}
.fr-element.fr-view ol,
.fr-element.fr-view ul,
.fr-element.fr-view p {
  margin-bottom: 12px;
}
.fr-element.fr-view ol {
  margin-left: 1.5rem;
  line-height: 1.7;
  list-style-type: decimal;
}
.fr-element.fr-view ul {
  margin-left: 1.5rem;
  line-height: 1.7;
  list-style-type: disc;
}

.vb_description {
  background-color: #fcf9f4;
  border-radius: 8px;
  .vb_description p {
    font-size: 12px;
    line-height: 1.4;
    color: #898989;
    margin-bottom: 12px;
  }
}

.btn-vb,
.btn.btn-vb {
  color: #fff;
  background-color: #3373ba;
  border-color: #3373ba;
  border-radius: 25px;
  padding: 10px 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    background-color: rgb(29, 82, 141);
    color: #fff !important;
  }
  &:active {
    background-color: rgb(29, 82, 141);
    box-shadow: 0 5px rgba(29, 82, 141, 0.7);
    transform: translateY(3px);
    color: #fff !important;
  }
}

.fr-view {
  .vb_table1 td,
  table.vb_table1 td,
  .vb_table2 td,
  table.vb_table2 td {
    border: none;
  }

  .vb_table1 {
    margin-top: 12px;
    margin-bottom: 12px;
    border-collapse: collapse;
    color: #353535;
    font-size: 12px;
    line-height: 1.4;

    thead tr th {
      padding: 6px;
      background: #f0f0f0;
      border: 1px solid #f0f0f0;
      vertical-align: middle;
    }
    tbody tr td {
      padding: 6px;
      border-bottom: solid 1px #f0f0f0;
      vertical-align: middle;
      ul,
      ol {
        margin-left: 20px;
        line-height: 1.4;
        font-size: 12px;
        color: #353535;
        li {
          margin-bottom: 6px;
        }
      }
      ul {
        list-style-type: disc;
      }
      ol {
        list-style-type: decimal;
      }
    }
  }

  .vb_table2 {
    border-collapse: collapse;
    color: #353535;
    font-size: 12px;
    line-height: 1.4;

    thead tr th {
      padding: 6px;
      background: #3373ba;
      border: 1px solid #f0f0f0;
      vertical-align: middle;
      color: #fff;
      text-align: center;
    }
    tbody tr td {
      padding: 6px;
      border-right: solid 1px #f0f0f0;
      vertical-align: top;
      ul,
      ol {
        margin-left: 20px;
        line-height: 1.4;
        font-size: 12px;
        color: #353535;
        li {
          margin-bottom: 6px;
        }
      }
      ul {
        list-style-type: disc;
      }
      ol {
        list-style-type: decimal;
      }
    }
    tbody tr:first-child td {
      border-top: 0;
    }
    tbody tr td:first-child {
      border-left: 0;
    }
    tbody tr:last-child td {
      border-bottom: 0;
    }
    tbody tr td:last-child {
      border-right: 0;
    }
  }
}

// modal
.content-show-modal {
  .modal-dialog {
    .modal-content {
      img {
        max-width: 100%;
      }
    }
  }
}

.modal-dialog {
  &.modal-size-large {
    max-width: 900px !important;
  }
}

.comment-list-row,
.recomment-list-row {
  padding: 5px 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ccc;
  &.not-border-bottom {
    border-bottom: none;
  }
  .action-btns {
    display: flex;
    button {
      margin-left: 5px;
    }
  }
  .includeIconOnly {
    width: 25px !important;
    height: 25px !important;
  }
  > div {
    display: flex;
    flex: 1;
    width: 100%;
  }
}
.recomment-list-row {
  .recomment-notice {
    display: flex;
    button {
      margin-right: 3px;
    }
  }
}
#hideConfirmModal {
  z-index: 100002;
}
#contentModal {
  z-index: 100002;
}

// ** authority admin
.authority-form {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  .authority-key {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .authority-enable {
    display: flex;
    margin-bottom: 20px;
    margin-left: 20px;
    align-items: center;
  }
  .authority-name {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .authority-label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
  }
  .authority-input {
    flex: 1;
  }
}

.authority-success-text {
  display: flex;
  align-items: center;
  color: #3373ba;
  font-size: 12px;
  font-weight: bold;
  button {
    margin-right: 10px;
  }
}
.authority-fail-text {
  display: flex;
  align-items: center;
  color: red;
  font-size: 12px;
  font-weight: bold;
  button {
    margin-right: 10px;
  }
}

// package
.flatpickr-calendar.open {
  z-index: 100002 !important;
}

// general
// margin
.vb-mr-5 {
  margin-right: 5px;
}

.divide-bar {
  margin: 10px 0px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.justify-content-end {
  justify-content: flex-end;
}

#delConfirmModal {
  z-index: 100002;
}

// 버튼 애니메이션 효과
@mixin hideTapHighlightColor() {
  //Prevent highlight colour when element is tapped
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin hardwareAccel() {
  //Improve performance on mobile/tablet devices
  transform: translateZ(0);
}

@mixin improveAntiAlias() {
  //Improve aliasing on mobile/tablet devices
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.bounce-hover-effect {
  transition-duration: 0.5s;
  transition-property: transform;

  @include hideTapHighlightColor();
  @include hardwareAccel();
  @include improveAntiAlias();

  &:hover {
    transform: translateY(-6px);
    animation-name: hover;
    animation-duration: 1.5s;
    animation-delay: 0.3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

.quickSight-iframe {
  width: 100%;
  height: calc(100vh - 180px);
  border: none;
}
